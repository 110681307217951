export default {
  baseStyle: {
    control: {
      padding: 1,
      _checked: {
        bg: 'blue.800',
      },
    },
  },
}
