import { extendTheme } from '@chakra-ui/react'
// Global style overrides
import styles from './styles'
import colors from './colors'
import fonts from './fonts'
// Foundational style overrides
// import borders from "./foundations/borders";
// Component style overrides

import Button from './components/button'
import Text from './components/text'
import Checkbox from './components/checkbox'

const overrides = {
  styles,
  colors,
  fonts,
  //   borders,
  // Other foundational style overrides go here
  components: {
    Button,
    Text,
    Checkbox,
    // Other components go here
  },
}
export default extendTheme(overrides)
