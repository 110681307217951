export default {
  global: {
    // styles for the `body`
    body: {
      bg: 'gray.100',
      color: 'grey.900',
      minWidth: 'fit-content',
    },
    // styles for the `a`
    a: {
      color: 'grey.900',
      _hover: {
        textDecoration: 'underline',
      },
    },
  },
}
